function doTheMagic(e) {
  var ctr = new ScrollMagic.Controller();
  var ua = navigator.userAgent.toLowerCase();
  var isSafari = false;

  if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
      console.log('its chrome');
      $('.bcg, video').width($(window).width());
    } else {
      isSafari = true;
      $('body').addClass('safari');
      $('.bcg, video').height(1000);
    }
  }

  $('video').each(function () {
    var v = document.getElementById($(this).attr('ID'));
    $(this).removeAttr('controls');

    new ScrollMagic.Scene({
      triggerElement: '#' + $(this).attr('ID'),
      duration: $(window).height(),
    })
      .addTo(ctr)
      .on('enter', function () {
        if (!isSafari) {
          v.play();
        }
      })
      .on('leave', function () {
        if (!isSafari) {
          v.pause();
        }
      });
  });

  !(function (e) {
    var t = [];
    var r = [];
    var n = [];

    $('.slide').each(function () {
      t.push('#' + $(this).attr('ID'));
      r.push('#' + $(this).attr('ID') + ' header');
    });

    $('.content-block').each(function () {
      n.push('#' + $(this).attr('ID'));
    });

    var o = new ScrollMagic.Controller(),
      t,
      r,
      n;

    if (!Modernizr.touch) {
      r.forEach(function (e, t) {
        {
          var r = t + 1;
          new ScrollMagic.Scene({ triggerElement: e, offset: -95 })
            .setClassToggle('#slide0' + r, 'is-active')
            .addTo(o);
        }
      }),
        n.forEach(function (t, r) {
          {
            var n = e(t).attr('id');
            new ScrollMagic.Scene({ triggerElement: t, triggerHook: 0.75 })
              .setClassToggle('#' + n, 'is-active')
              .on('enter', function (o) {
                e('nav').attr('class', 'is-light');
              })
              .addTo(o);
          }
        }),
        t.forEach(function (t, r) {
          new ScrollMagic.Scene({ triggerElement: t })
            .on('enter', function (o) {
              e('nav').removeAttr('class');
            })
            .addTo(o);
        }),
        t.forEach(function (t, r) {
          {
            var n = e(t).find('.bcg');
            new ScrollMagic.Scene({
              triggerElement: t,
              triggerHook: 1,
              duration: '100%',
            })
              .setTween(
                TweenMax.from(n, 1, {
                  y: '-40%',
                  autoAlpha: 0.3,
                  ease: Power0.easeNone,
                })
              )
              .addTo(o);
          }
        });
      var a = new TimelineMax();
      a.to(e('#intro header, .scroll-hint'), 0.2, {
        autoAlpha: 0,
        ease: Power1.easeNone,
      })
        .to(e('#intro .bcg'), 1.4, { y: '20%', ease: Power1.easeOut }, '-=0.2')
        .to(e('#intro'), 1, { autoAlpha: 1, ease: Power1.easeNone }, '-=1.4');
      {
        new ScrollMagic.Scene({
          triggerElement: '#intro',
          triggerHook: 0,
          duration: '100%',
        })
          .setTween(a)
          .addTo(o);
      }
      o.scrollTo(function (e) {
        TweenMax.to(window, 1, { scrollTo: { y: e }, ease: Power1.easeInOut });
      }),
        e(document).on('click', "a[href^='#']", function (t) {
          var r = e(this).attr('href');
          e(r).length > 0 &&
            (t.preventDefault(),
            o.scrollTo(r),
            window.history &&
              window.history.pushState &&
              history.pushState('', document.title, r));
        });
    }
  })(jQuery);
}

!(function (e) {
  /*
    Change the logo colour as we scroll over a light background section.
  */
  /*
  $('.light-bg').each(function () {
    var h = $(this).height() + 150;
    console.log('height: ' + h);
    var controller = new ScrollMagic.Controller({
      globalSceneOptions: { duration: h },
    });
    var scene = new ScrollMagic.Scene({ triggerElement: $(this).attr('ID') })
      .setClassToggle('#header', 'dark') // add class toggle
      .addIndicators({ name: $(this).attr('ID') + 'tween css class' })
      .addTo(controller);

    scene.triggerHook(0.3);
  });
  */
  if ($('body').attr('ID') == 'page-home') {
    if (jQuery(window).width() > 1000) {
    
      $('#header').css({ opacity: 0 });

      $('#header').stop(true, true).delay(3500).animate(
        {
          opacity: 1,
        },
        1000
      );
    }
  }

  $(this).scrollTop(0);

  if (jQuery(window).width() > 1000) {
    doTheMagic(e);
    
    
  }

  jQuery('#menutoggle .fa-bars').click(function () {
    jQuery('.topnav ul').css({ width: '100%' });
    jQuery('.topnav ul').fadeIn(300);
    jQuery('.fa-bars').hide();
    jQuery('.fa-times').show();
    jQuery('.topnav').addClass('active');
  });
  jQuery('#menutoggle .fa-times').click(function () {
    jQuery('.topnav ul').fadeOut(300);
    jQuery('.fa-bars').show();
    jQuery('.fa-times').hide();
    jQuery('.topnav').removeClass('active');
  });

  $('.logolarge').stop(true, true).delay(3000).animate(
    {
      opacity: 0,
    },
    1000
  );

  $('#header').stop(true, true).delay(3000).animate(
    {
      opacity: 1,
    },
    1000
  );

  $('.emaillink').click(function () {
    window.location = 'mailto:' + $(this).text();
  });

  $('.scroll-hint').click(function () {
    var $el = $(this); //record the elem so you don't crawl the DOM everytime
    var bottom = $el.position().top + $el.outerHeight(true);
    window.scrollTo(0, bottom);
  });

  jQuery(window).scroll(function () {
    if (jQuery(window).width() > 1000) {
      if ($(window).scrollTop() > 10) {
        $('.logolarge').animate(
          {
            opacity: 0,
          },
          1000
        );
      }
    }
  });
})(jQuery);

jQuery(document).ready(function(e){
  doTheMagic(e);
  jQuery("video").each(function(){
    jQuery(this).css({"transform": "none"});
  });
});

jQuery(window).resize(function(e){
  doTheMagic(e);
  jQuery("video").each(function(){
    jQuery(this).css({"transform": "none"});
  });
});